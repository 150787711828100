<template>
  <div>
    <div class="contents">
      <submenu curbg=2></submenu>
      <div class="tableSearch">
         <!-- <el-button @click="yaoqing" type="primary" style="float:right;margin-left:20px;">邀请供应商</el-button> -->
         <el-button @click="()=>addRelationFlag=true" type="primary" style="float:right" icon="el-icon-plus">添加供应商</el-button>

          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="100px">
            <el-form-item label="供应商编号" style="margin-bottom:0px">
              <el-input v-model="searchForm.code" clearable  style="width:190px"
                placeholder="请输入供应商编号"></el-input>
            </el-form-item>
            <el-form-item label="供应商名称" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable  style="width:190px"
                placeholder="请输入供应商名称"></el-input>
            </el-form-item>
               <el-form-item style="margin-bottom:0px">
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
            <el-button @click="reset">重置</el-button>
            </el-form-item>
          </el-form>
      </div>

    <div class="mainbox">
       <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="50" header-align="center" align="center"
          fixed
          >
        </el-table-column>
        <el-table-column
          prop="org.name"
          label="供应商名称"
          width="120"
          fixed
          >
        </el-table-column>
        <el-table-column
          prop="alias"
          label="别名"
          width="120">
        </el-table-column>
        <el-table-column
          prop="org.code"
          label="供应商编号"
          width="100">
        </el-table-column>

        <el-table-column
          label="合作状态"
          width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.cooperation==1" type="success">合作中</el-tag>
            <el-tag v-if="scope.row.cooperation==0" type="danger">不合作</el-tag>
           </template>
        </el-table-column>

        <el-table-column
          label="标签"
          width="120">
          <template slot-scope="scope">
             <el-tag
             v-for="item in scope.row.tags" :key="item" style="margin:2px;">{{ item }}</el-tag>
           </template>
        </el-table-column>

        <el-table-column
          prop="ownerName"
          label="负责人"
          >
        </el-table-column>

        <el-table-column
          prop="org.contactName"
          label="联系人" width="100">
        </el-table-column>

        <el-table-column
          prop="org.contactPhone"
          label="联系电话" width="100">
        </el-table-column>

        <el-table-column
          prop="org.address"
          label="地址" width="200">
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注" width="120">
        </el-table-column>

        <el-table-column
      label="操作"
            fixed="right"
      width="100" header-align="center" align="center">
      <template slot-scope="scope">

        <el-button @click="showEditDialog(scope.row)" type="text" size="small">编辑</el-button>  

      </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
           <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          @size-change="handleSizeChange"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      </div>
    </div>
    <el-dialog title="更多设置" :visible.sync="configViewDialogVisible" width="600px">
        <el-form :model="config" label-position="right" label-width="200px"> 
          <el-form-item label="供应商商品匹配策略">
            <el-radio-group v-model="config.skuMatchStrategy.strategy" v-if="config.skuMatchStrategy">
              <el-radio label="MANUAL">手动匹配</el-radio>
              <el-radio label="AUTO">智能自动匹配</el-radio>
            </el-radio-group>            
          </el-form-item>

          <el-form-item label="交单策略">
            <el-radio-group v-model="config.sendSupplierStrategyConfig.strategy" v-if="config.sendSupplierStrategyConfig">
              <el-radio label="MANUAL">手动交单</el-radio>
              <el-radio label="AUTO">定时自动交单</el-radio>
            </el-radio-group>            
          </el-form-item>
          <el-form-item label="定时自动交单时间：" v-if="config.sendSupplierStrategyConfig && config.sendSupplierStrategyConfig.strategy=='AUTO'">
            <div class="timebox">
            <div class="additem" @click="addfun">+</div>
            <div v-for="(item, index) in deadlines" :key="index" style="margin-bottom:10px"> 
            <el-time-picker
                v-model="item.value"
                placeholder="任意时间点"
                format="HH:mm"
              >
            </el-time-picker>
            <div class="delitem" v-if="index>0" @click="delfun(index)">×</div>
            </div>
            </div>
          </el-form-item>
        </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="closeConfig()">取消</el-button>
              <el-button type="primary" @click="setConfig(config)">确定</el-button>
            </div>
    </el-dialog>

    <el-dialog :title="'编辑供应商信息： ' + editRow.name" :visible.sync="editDialogFlag" width="600px"
          :before-close="handleClose"
    >
        <el-form :model="config" label-position="right" label-width="100px"> 
          <el-form-item label="别名">
              <el-input v-model="editRow.alias" prefix-icon="el-icon-edit" autocomplete="off" placeholder="请输入别名" 
              style="width: 280px;"></el-input>       
          </el-form-item>

          <el-form-item label="标签">
            <div class="timebox">
              <el-input style="width:250px;margin-bottom:10px;" v-model="tag"></el-input>
              <div class="additem" @click="addTag">+</div>
                <div v-for="(item, index) in editRow.tags" :key="index" style="margin-bottom:10px"> 
                  <el-tag>{{item}}</el-tag>
                  <div class="delitem" @click="delTag(index)">×</div>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="等级">
              <el-select v-model="levelName" placeholder="请选择等级" clearable @change="changeLevel">
                    <el-option
                      v-for="item in levels"
                      :key="item.id"
                      :label="item.name +' / ' + item.code"
                      :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>

          <k-select ref="kSelect"
                v-model="ownerId"
                prop="ownerId"
                label="销售负责人"
                placeholder="请选择销售负责人"
                optionKey="id"
                optionValue="id"
                optionLabel="nickName"
                clearable
                :filterMethod="getPurchaserList"
                :disableWhen="(opt) => opt.status == 2"
            ></k-select>

          <el-form-item label="备注">
            <el-input type="textarea"
              :rows="2" 
              v-model="editRow.remark" autocomplete="off" placeholder="请输入备注" style="width: 280px;">
            </el-input>       
          </el-form-item>

        </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="disableCooperation()" style="float:left;" :disabled="editRow.cooperation==0">不再合作</el-button>

              <el-button @click="handleClose()">取消</el-button>
              <el-button type="primary" @click="edit()">确定</el-button>
            </div>
    </el-dialog>

    <el-dialog title="邀请供应商" :visible.sync="yaoDialogVisible" width="600px">
       <div class="kuanCodeyaoqing">
        <img :src="qrcode && qrcode.qrCodeUrl" class="codesize"/>
        <p>微信扫码，将邀请链接发送给您的供应商好友</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeYao()">确定</el-button>
      </div>
     </el-dialog> 
 

     <el-dialog title="添加供应商" :visible.sync="addRelationFlag" width="600px">
        <el-form :model="relationForm" label-position="right" label-width="110px"> 
          <el-form-item label="供应商名称" >
            <el-input placeholder="请输入供应商名称" v-model="relationForm.name" style="width:200px;"></el-input>
          </el-form-item>

            <el-form-item label="联系人" >
              <el-input v-model="relationForm.contact" clearable  style="width:200px"
                placeholder="请输入供应商联系人名称"></el-input>
            </el-form-item>

            <el-form-item label="联系电话" >
              <el-input v-model="relationForm.phone" clearable  style="width:200px"
                placeholder="请输入供应商联系电话"></el-input>
            </el-form-item>

            <el-form-item label="经营地址" >
              <el-input v-model="relationForm.address" clearable  style="width:400px"
                placeholder="请输入供应商的经营地址"></el-input>
            </el-form-item>

        </el-form>
          <div slot="footer" class="dialog-footer">
              <el-button @click="() => addRelationFlag=false">取消</el-button>
              <el-button type="primary" @click="addWmsSupplier()">确定</el-button>
            </div>
    </el-dialog> 

    <el-dialog :title="'供应商结算设置： ' + settleRow.org.name" :visible.sync="settleDialogFlag" width="800px"
      :before-close="handleClose"
    >
        <el-form :model="settle" label-position="right" label-width="200px"> 
          <el-form-item label="结算方式" required>
              <el-radio-group v-model="settle.type" placeholder="请选择结算方式">
                    <el-radio label="CASH" >现结</el-radio>
                    <!-- <el-radio label="BILL">期结</el-radio> -->
              </el-radio-group>
          </el-form-item>

          <el-form-item label="结算周期" v-if="settle.type=='BILL'">
              <el-select v-model="settle.period" placeholder="请选择结算周期">
                    <el-option label="月结" value="MONTH">月结</el-option>
                    <el-option label="周结" value="WEEK">周结</el-option>
                    <el-option label="日结" value="DAY">日结</el-option>
              </el-select>
          </el-form-item>

          <el-form-item label="账单日（每月）" v-if="settle.type=='BILL' && settle.period=='MONTH'">
              <el-select v-model="settle.perMonth" placeholder="请选择结算方式" multiple :multiple-limit="3" clearable>
                    <el-option
                      v-for="item in month"
                      :key="item+1"
                      :label="(item+1)+'号'"
                      :value="item+1">
                </el-option>
              </el-select>
              <label class="settle-tips">可选择多个时间，例如：半月结可以选择每月1号，15号作为账单日</label>
          </el-form-item>

          <el-form-item label="账单日（每周）" v-if="settle.type=='BILL' && settle.period=='WEEK'">
              <el-select v-model="settle.perWeek" placeholder="请选择结算方式" multiple :multiple-limit="3" clearable>
                    <el-option
                      v-for="(item, index) in week"
                      :key="index"
                      :label="item"
                      :value="index+1">
                </el-option>
              </el-select>
              <label class="settle-tips">可选择多个时间，例如：半周结可以选择每周一，每周四作为账单日</label>
          </el-form-item>

          <el-form-item label="系统自动制作应付账单" v-if="settle.type=='BILL'">
              <el-radio-group v-model="settle.automatic" placeholder="系统自动制作应付账单">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
              </el-radio-group>
              <label class="settle-tips">选择是，系统会按照设置好的结算周期，以交单时间作为结算依据，自动生成收款账单</label>
          </el-form-item>

          <el-form-item label="账单起始日" v-if="settle.type=='BILL' && settle.automatic==1">
            <el-date-picker v-model="settle.startAt" placeholder="请选择账单开始日" type="date"></el-date-picker>
            <el-button style="margin-left:20px;" type="success" size="small" plain @click="mock()">查看模拟账单日</el-button>
            <label class="settle-tips">系统会自动比较账单开始日和上一个账单日，系统会选择临近当前账单日的时间作为账单起始日。您可以点击上面的【查看模拟账单日】按钮，查看系统接下来会如何生成账单。</label>
          </el-form-item>

          <el-form-item label="收款供应商名称">
            <label>{{settleRow.collectorOrgName}}</label>
          </el-form-item>

          <el-form-item label="收款供应商编号">
            <label>{{settleRow.collectorOrgCode}}</label>
          </el-form-item>

          <k-select ref="collectorSelector"
                v-model="collectorOrgId"
                :name="collectorOrgName"
                label="收款供应商账号"
                placeholder="输入名称、编号或手机号查找"
                optionKey="id"
                optionValue="id"
                optionLabel="name"
                optionAlias="code"
                clearable
                :filterMethod="searchSupplier"
            >
            </k-select>
            <label style="color:red;font-size:12px;margin-left:200px;margin-right:100px;">
              安全起见，向供应商结算货款时，供应商只有使用此账号登录才能收款。
            </label>

        </el-form>

        <el-table empty-text=" " :data="mockList" v-show="settle.type=='BILL' && settle.automatic==1">
          <el-table-column prop="name" label="模拟账单名称"></el-table-column>
          <el-table-column prop="from" label="账单起"></el-table-column>
          <el-table-column prop="to" label="账单止"></el-table-column>
        </el-table>

          <div slot="footer" class="dialog-footer">
        <div style="color:red;font-size:13px;margin-bottom:20px;margin-left:200px;">修改结算方式可能会影响后续对账，请谨慎操作。</div>
              <el-button @click="handleClose()">取消</el-button>
              <el-button type="primary" @click="configSettlement()">确定</el-button>
            </div>
    </el-dialog>

  </div>
</template>

<script>
import { mySupplier } from "@/api/common";
import { setConfig, inviteSupplier , edit, disableCooperation, configSupplierSettlement, listSupplier, searchSupplier} from "@/api/supplier"
import { addWmsSupplier } from "@/api/wms/wmsSupplier"
import { getUserList } from "@/api/user"
import { getLevel } from "@/api/level"
import { mockBill } from "@/api/payorder"
import { MessageBox } from 'element-ui'
import submenu from "@/components/submenu";
import kSelect from "@/components/kselect"
let moment = require('moment');

export default {
  name: 'Supplier',
  components: { submenu, kSelect },
  data() {
    return {
      searchForm: {
        code: '', 
        name: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      kuaiDiflag: false,
      loadingFile: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: [],
      configViewDialogVisible: false,
      deadlines: [],
      yaoDialogVisible: false,
      config:{},
      qrcode: {},
      editDialogFlag: false,
      editRow: {},
      tag: "",
      levels: [],
      levelId: 0,
      levelName: "",
      ownerId: null,
      getPurchaserList: getUserList,
      addRelationFlag: false,
      relationForm: {},
      settleDialogFlag: false,
      settleRow: {
        org: {
          name: "",
          wechatBar: "",
        }
      },
      settle: {
        type: "CASH",
        period: "MONTH",
        perMonth: null,
        perWeek: null,
        automatic: 0,
        startAt: null,
      },
      month: Array.from({length:28}, (v,k) => k),
      week: ['星期一','星期二','星期三','星期四','星期五','星期六','星期天'],
      listSupplier: listSupplier,
      searchSupplier: searchSupplier,
      collectorOrgId: null,
      collectorOrgName: null,
      mockList: [],
    }
  },
  methods: {
    mock() {
      const that = this
      let param = {
        id: this.settleRow.id,
        settlement: this.settle,
        name: this.settleRow.org.name,
      }
      param.settlement.startAt = param.settlement.startAt ? moment(param.settlement.startAt).format('YYYY-MM-DD HH:mm:ss') : ''
      mockBill(param).then(res => {
        if(res.code===200) {
          that.mockList = res.data
        } else {
          this.$message.error(res.message);
        }
      })
    },
    configSettlement() {
      const that = this
      let param = {
        id: this.settleRow.id,
        settlement: this.settle,
        collectorOrgId: this.collectorOrgId
      }
      param.settlement.startAt = param.settlement.startAt ? moment(param.settlement.startAt).format('YYYY-MM-DD HH:mm:ss') : ''
      configSupplierSettlement(param).then(res => {
        if(res.code === 200) {
          this.$message.success(res.message)
          this.handleClose()
          for(var i=0;i<that.tableData.length;i++) {
            if(that.tableData[i].id == res.data.id) {
              that.tableData[i] = res.data
              that.$set(that.tableData, i, that.tableData[i])
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    showSettlementDialog(row) {
      const that = this
      this.settleRow = row
      this.settleDialogFlag = true
      this.settle = this.settleRow.settlement
      this.collectorOrgId = this.settleRow.collectorOrgId
      this.collectorOrgName = this.settleRow.collectorOrgName
      console.log(this.collectorOrgId, this.collectorOrgName)
      this.$nextTick(()=>{
          that.$refs.collectorSelector.onFocus()
      })
    },
    addWmsSupplier() {
      const param = this.relationForm;
      const that = this
      addWmsSupplier(param).then(res => {
        if(res.code === 200) {
          this.$message.success(res.message);
          that.relationForm = {}
          that.addRelationFlag = false
          this.getTopSearch()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    disableCooperation() {
      this.$confirm("解除合作关系后，将无法匹配商品和订单到该供应商, 是否确认?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
            const param = {
              id: this.editRow.id
            }
            disableCooperation(param).then(res => {
              if(res.code === 200) {
                this.handleClose()
                this.getTopSearch()
              } else {
                this.$message.error(res.message)
              }
            })
        })
        .catch(() => {});
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    addfun(){
      var that = this
      var objs= {
        value: ''
      }
      that.deadlines.unshift(objs)
    },
    delfun(n){
      var that = this
      that.deadlines.splice(n,1)
    },
    addTag() {
      if(this.tag=="") return
      this.editRow.tags.push(this.tag)
      this.tag = ""
    },
    delTag(index) {
      this.editRow.tags.splice(index,1)
    },
    showEditDialog(row) {
      this.editRow = row
      this.editDialogFlag = true
      this.levelName = row.levelName
      this.levelId = row.level
      this.ownerId = row.ownerId
      const that = this
      const param = {
        role: "retailer",
        pageNo:1,
        pageSize:1000,                        
      }
      getLevel(param).then((res)=>{
        if(res.code ===200) {
          that.levels = res.data.rows
        }else{
          that.$message.error(res.message)
        }
      })

      this.$nextTick(()=>{
          that.$refs.kSelect.onFocus()
      })
    },
    changeLevel(value) {
      this.levelId = value
    },
    reset() {
      this.searchForm =  {
        code: '', 
        name: ''
      }
      this.getTopSearch()
    },
    edit() {
      const that = this
      let param = this.editRow
      param.level = this.levelId
      param.levelName = ""
      param.ownerId = this.ownerId
      edit(param).then(res => {
        if(res.code ===200) {
          that.handleClose()
          that.getTopSearch()
          that.$message.success(res.message)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    moreConfig(row) {
      console.log(row)
      this.configViewDialogVisible = true
      this.config = row.supplierConfig
      this.config.id = row.id
      if(this.config.sendSupplierStrategyConfig.deadlines.length > 0) {
        this.deadlines = this.config.sendSupplierStrategyConfig.deadlines.map(iy => {
          var objs={}
          objs.value = new Date('1970-01-01 ' + iy)
          return objs
        })
        this.deadlines.unshift({value:''})
      }else{
        this.deadlines = [{
          value: ''
        }]
      }
    },
    setConfig(config) {
      const that = this      
      var param = config
      param.sendSupplierStrategyConfig.deadlines = that.deadlines.filter(d => d.value!="").map(item => {
        return moment(item.value).format('HH:mm')
      })
      param.skuMatchStrategy = {
        "strategy": that.config.skuMatchStrategy.strategy
      }
      setConfig(config).then(res => {
        if(res.code ===200) {
          this.configViewDialogVisible = false
          that.getTopSearch()
          that.$message.success(res.message)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    closeConfig() {
      this.configViewDialogVisible = false
      this.deadlines = []
    },
    handleClick(row) {
      this.$router.push({
        path: "/sendtuan",
        query:{
          id: row.org.id
        }
      })
    },
    yaoqing() {
      const that = this
      var objs = {
      }
      inviteSupplier(objs).then(res=>{
        console.log(res)
        if(res.code == 200){
          that.qrcode = res.data
          that.yaoDialogVisible = true
        }
      })
    },
    closeYao(){
      this.yaoDialogVisible= false
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      mySupplier(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          nodataArr.map((item)=>{
            item.xiadanTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
          })
          that.tableData = nodataArr
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleClose(){
      this.kuaiDiflag = false;
      this.editDialogFlag = false
      this.settleDialogFlag = false
      this.collectorOrgId = null
      this.collectorOrgName = null
      this.mockList = []
      if(this.$refs.kSelect) {
        this.$refs.kSelect.onClear()
      }
      if(this.$refs.collectorSelector) {
        this.$refs.collectorSelector.onClear()
      }
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size:12px;
}
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.caozuoqu{
  float:right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
.sizeCss{
  width: 100px;
  height: 100px;
  display: block;
}

.timebox{
  width: 300px;
  display: block;
}
.additem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #FFF;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #07C160;
  margin-left: 10px;
  cursor: pointer;
}
.delitem{
  width: 32px;
  height: 32px;
  display: block;
  float:right;
  color: #999;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  background-color: #f3f3f3;
  margin-left: 10px;
  cursor: pointer;
}
.kuanCodeyaoqing{
  display: block;
  width: 100%;
  min-height: 260px;
}
.kuanCodeyaoqing p{
  display: block;
  text-align: center;
  font-weight: bold;
  color: #333;
}
.codesize{
  width: 200px;
  height: 200px;
  display: block;
  border: 5px solid #eee;
  margin: 0 auto 20px;
  border-radius: 10px;
}
.li {
  padding-left: 1em; 
  text-indent: -.7em;
  list-style: none;
}

.li::before {
  content: "• ";
  font-size: 1.5em;
  color: #07C160; /* or whatever color you prefer */
}

.settle-tips{
  font-size:12px;
  float:left;
  color:#9f9f9f;
  margin-right:20px;
  line-height: 20px;
}
</style>